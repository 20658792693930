import React, { memo, MutableRefObject, useCallback } from 'react';

import { RcmCardType, RecEvents } from 'common/RecEvents';

import { CardLayout, CardPropsType } from '../Base';

type RecLayoutPropsType = CardPropsType & {
  clusterId: CardData['id'] | null;
  rcmKey: RcmCardType['rcmKey'];
  position: RcmCardType['position'];
  contextItemId: RcmCardType['contextItemId'];
};

/**
 * Обертка над обычной карточкой, добавляющей отслеживание и отправку событий для рекомендаций.
 * @param clusterUrl - url кластера
 * @param recordId - id видео в видеоплатформе
 * @param isLive - флаг, что идет видеотрансляция
 * @param imageSrc - url картинки
 * @param imageParams - Объект с информацией по картинке
 * @param title - Заголовок новости
 * @param date - Дата в продовом формате "n минут/секунд/часов назад"
 * @param videoIconType - Тип иконки для карточки видео-кластера ("BIG" | "SMALL")
 * @param topicName - Название рубрики отображаемого кластера;
 * @param commentsCount - Количество комментов на новости
 * @param noComments - флаг, что комменты отключены
 * @param style - Стили. Все стили приходят снаружи, сам компонент своих стилей не имеет
 * @param top100 - Индекс карточки для топ100
 * @param linkRef - реф на обертку ссылки
 * @param onClick - функция, срабатывающая при клике на кнопку;
 * @param rcmKey — ключ, по которому храним blockID в конфигурации (topNow например)
 * @param position — индекс в списке выводимых карточек
 * @param contextItemId - идентификатор страницы, на которой используется блок (clusterID, pageName или еще что-то).
 * @param isCompactCard - флаг что карточка должна иметь компактный вид (редизайн вертикали 2, максимальная ширина 430px)
 */
export const RecLayout = memo(
  ({
    clusterUrl,
    recordId,
    isLive,
    imageSrc,
    imageParams,
    title,
    date,
    topicName,
    style,
    videoDuration,
    commentsCount,
    top100,
    rcmKey,
    position,
    clusterId,
    contextItemId,
    isCompactCard,
    noComments,
  }: RecLayoutPropsType) => {
    const render = useCallback(
      (ref: MutableRefObject<HTMLAnchorElement>, onClick: () => void) => (
        <CardLayout
          clusterUrl={clusterUrl}
          recordId={recordId}
          isLive={isLive}
          imageSrc={imageSrc}
          imageParams={imageParams}
          title={title}
          date={date}
          topicName={topicName}
          style={style}
          videoDuration={videoDuration}
          commentsCount={commentsCount}
          top100={top100}
          onClick={onClick}
          linkRef={ref}
          isLazy
          isCompactCard={isCompactCard}
          noComments={noComments}
        />
      ),
      [
        clusterUrl,
        commentsCount,
        date,
        imageParams,
        imageSrc,
        isCompactCard,
        isLive,
        noComments,
        recordId,
        style,
        title,
        top100,
        topicName,
        videoDuration,
      ],
    );

    return (
      <RecEvents
        clusterID={clusterId}
        rcmKey={rcmKey}
        position={position}
        contextItemId={contextItemId}
        // @ts-expect-error: ¯\_(ツ)_/¯
        render={render}
      />
    );
  },
);
