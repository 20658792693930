import cn from 'classnames';
import React, { memo, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  getIsEditorOrExpertPage,
  getIsListPage,
} from 'config/constants/common';
import { timeToDuration } from 'utils/date/dtime';
import { useTypograf } from 'utils/typograf';

import { IntegratedVideoPlayer } from '../IntegratedVideoPlayer';

import s from './styles.module.css';

type imageParams = {
  width?: number;
  heigth?: number;
  s3?: boolean;
};

export type CardPropsType = {
  clusterUrl: string;
  recordId?: CardData['video']['recordId'];
  isLive?: CardData['video']['isLive'];
  imageSrc?: string;
  imageParams?: imageParams;
  title?: string;
  date?: string;
  style: StylesType;
  topicName?: string | null;
  videoDuration?: number;
  commentsCount?: number;
  noComments: boolean;
  top100?: string;
  linkRef?: MutableRefObject<HTMLAnchorElement>;
  onClick?: () => void;
  isCompactCard?: boolean;
  isSecondaryCard?: boolean;
  isLazy?: boolean;
};

/**
 * (MOBILE | DESKTOP)
 * Общий компонент - шаблон вёрстки карточки новостей для главной вертикали redesign-версии
 * @param clusterUrl - url кластера
 * @param recordId - id видео в видеоплатформе
 * @param isLive - флаг, что идет видеотрансляция
 * @param imageSrc - url картинки
 * @param imageParams - Объект с информацией по картинке
 * @param title - Заголовок новости
 * @param date - Дата в продовом формате "n минут/секунд/часов назад"
 * @param topicName - Название рубрики отображаемого кластера;
 * @param style - Стили. Все стили приходят снаружи, сам компонент своих стилей не имеет
 * @param videoDuration - Продолжительность видео
 * @param commentsCount - Количество комментов на новости
 * @param top100 - Индекс карточки для топ100
 * @param linkRef - реф на обертку ссылки
 * @param onClick - функция, срабатывающая при клике на кнопку.
 * @param isCompactCard - флаг что карточка должна иметь компактный вид (редизайн вертикали 2, максимальная ширина 430px)
 * @param isLazy - флаг ленивой загрузки. работает только для изображений через background-image
 */

export const CardLayout = memo(
  ({
    clusterUrl,
    recordId,
    isLive,
    imageSrc,
    imageParams,
    title,
    date,
    topicName,
    style,
    videoDuration,
    commentsCount,
    noComments,
    top100,
    linkRef,
    onClick,
    isCompactCard,
    isLazy,
  }: CardPropsType) => {
    const pageName = useSelector(selectPageName);
    const cookedTitle = useTypograf(title);
    const top100AttributeWithValue = useTop100AttributeWithValue(top100);
    const duration = videoDuration ? timeToDuration(videoDuration) : '';

    const isListPage = getIsListPage(pageName);
    const isEditorOrExpertPage = getIsEditorOrExpertPage(pageName);

    const isModifiedStyles = isListPage || isEditorOrExpertPage;

    return (
      <div
        className={cn(
          style.container,
          isModifiedStyles && style.container_mod,
          !imageSrc && style.container_noImage,
        )}
        {...(top100 && top100AttributeWithValue)}
      >
        <a
          href={clusterUrl}
          aria-label={cookedTitle}
          className={style.card}
          ref={linkRef}
          onClick={onClick}
        >
          <div
            className={cn(
              style.imgContainer,
              isModifiedStyles && style.imgContainer_mod,
              isModifiedStyles && !imageSrc && style.imgContainer_hide,
              isCompactCard && style.imgContainerCompact,
            )}
          >
            {!!duration && <div className={style.duration}>{duration}</div>}
            {!!imageSrc && (
              <RamblerImage
                src={imageSrc}
                alt={title}
                width={imageParams?.width || undefined}
                height={imageParams?.heigth || undefined}
                className={style.img}
                isS3={imageParams?.s3}
                isLazy={isLazy}
                aria-hidden
              />
            )}
            {!!recordId && (
              <ErrorBoundary componentName="IntegratedVideoPlayer">
                <IntegratedVideoPlayer
                  recordId={recordId}
                  isLive={isLive}
                  style={style}
                  clusterUrl={clusterUrl}
                />
              </ErrorBoundary>
            )}
          </div>
          <div className={style.allInfoContainer}>
            {!!title && <div className={style.title}>{cookedTitle}</div>}
            {!!topicName && <div className={style.topic}>{topicName}</div>}
            <span className={cn(s.metaInfo, style.metaInfo)}>
              {!!date && date}
              {!noComments && (
                <CommentsCounter
                  className={cn(s.icon, style.commentsIcon)}
                  count={commentsCount}
                />
              )}
            </span>
          </div>
        </a>
      </div>
    );
  },
);
